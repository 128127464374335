import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Column, Stack, StackItem, Axis, ContentContainer, Dbg, Debug } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage, Title } from './Typography';
import _ from 'lodash';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import FileList from './FlieList';

import { 
  getDeal,
  resetState,
  setDeal,
  addReviewMessage
} from "./redux/actions";
import ToolBar from './ToolBar';
import DealInfoBlock from './DealInfoBlock';
import DealApprovalResultBlock from './DealApprovalResultBlock';
import ReviewTextBlock from './ReviewTextBlock';
import { Card, CardContent } from '@mui/material';
import { getPropValue } from './helpers';
import uuid from 'react-uuid';

function DealAnswerPage(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const location = useLocation();
  const navigate = useNavigate();
  const docId = location.state.docId;
  const lt = stateObj.lt;
  const currentUser = stateObj.currentUser;
  const deals = stateObj.deals;
  const $ = window.$;
  const [fileListHeight, setFileListHeight] = React.useState(400);
  const [checkData, setCheckData] = useState({checkId:0, checkLogs:[]});
  const [loading, setLoading] = useState(false);
  const workerDeals = stateObj.workerDeals;
  const [isUserWorker, setIsUserWorker] = useState(false);
  
  useEffect(()=>{
    //для тестирования
    if (!currentUser) {
      alert("currentUser:null");
    }
    if (!deals) {
      alert("deals:null");
    }
    setLoading(true);
    dispatch(getDeal(docId, (deal)=>{
      let f = _.find(workerDeals, x => x.ID === deal.ID);
      if (f) {
        setIsUserWorker(true);
      }
      setLoading(false);
      try {
        let n = getPropValue(deal, "PROPERTY_300");
        let logs = getPropValue(deal, "PROPERTY_311", -1);
        setCheckData({checkId:n, checkLogs:logs});
      } catch(e) {}
    }))
  },[]);

  //console.log(_.find(deals, x => x.ID === docId));

  let dealInfoMinWidth = $(document).width()-600;

  const handleRevoke = ()=>{
    let deal = _.find(deals, x => x.ID === docId);
    if (deal && currentUser) {
      let dt = new Date();
      let y = dt.getFullYear();
      let m = `0${dt.getMonth()+1}`.substr(-2);
      let d = `0${dt.getDate()}`.substr(-2);
      let currentDt = `${d}.${m}.${y}`;
      let fields = {
        "PROPERTY_314": {
          "0": "Отозван"
        },
        "PROPERTY_316": {
          "0": currentDt
        }
      }

      let newMsg = {id:uuid(), date:new Date(), text: "Документ отозван с согласования", user:currentUser.NAME+" "+currentUser.LAST_NAME, user_id:currentUser.ID}; 
        dispatch(addReviewMessage(deal.ID, newMsg, (data)=>{
          //console.log(data);
          dispatch(setDeal(deal.ID, fields, (id)=>{
            navigate('/', { state: {} });
          }));
        }));
      
    }
  }

  
  
  return (
    <ThemeProvider theme={stateObj.theme}>
      <Column left={24} right={24} bottom={80}>
        <Row top={8}><ToolBar state="approval"></ToolBar></Row>
        { loading &&
        <LinearProgress />
        }
        <Row top={32} bottom={24}>
          <Header1>Согласование №{docId}</Header1>
        </Row>
        <Row>
          <Column width="calc(100% - 416px)">
            <Card>
              <CardContent>
                <DealInfoBlock docId={docId} minWidth={dealInfoMinWidth} editable={false}></DealInfoBlock>
              </CardContent>
            </Card>
            <Column top={36}>
              <Card>
                <CardContent>
                  <Row top={16} bottom={24}><Header2>Результат согласования</Header2></Row>
                  <DealApprovalResultBlock checkData={checkData}></DealApprovalResultBlock>
                </CardContent>
              </Card>
            </Column>
            <Row top={36}><Header2>Ваше решение</Header2></Row>
              <Row top={24}>
              <Row width='100%'><Button fullWidth variant='contained' size='large' color='error' onClick={handleRevoke}>Отозвать документ</Button></Row>
            </Row>
          </Column>
          <Column left={16} width={400}>
            <FileList type="deal" height={fileListHeight} title="Файлы документа:" allowUploadFiles={isUserWorker ? true : false} allowDeleteFiles={true} docId={docId}></FileList>
            <Column top={16}>
              <Row top={0} bottom={12}><Subtitle1>Переписка по согласованию</Subtitle1></Row>
              <Card>
                <ReviewTextBlock docId={docId} allowSend={true}></ReviewTextBlock>
              </Card>
            </Column>
          </Column>
          
        </Row>
      </Column>
    </ThemeProvider>
  );
}
  
export default DealAnswerPage;
  



