import axios from 'axios';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { 
  RESET_STATE,
  SET_CALCULATED_LAYOUT,
  GET_DOC_TYPES,
  GET_PARTNERS,
  GET_SIGNERS,
  GET_CRC,
  GET_PARENT_DOCS,
  GET_INITIATOR,
  GET_WORKER,
  SET_DEAL,
  GET_DEALS,
  GET_CURRENT_USER
} from "./types";
import { calc } from '../layout';

const testUsers = [
  {
      "ID": "92",
      "NAME": "Тестовый",
      "LAST_NAME": "Сотрудник",
      "EMAIL": "ya.bazhenova2010@yandex.ru",
      "WORK_POSITION": "",
  },
  {
      "ID": "145",
      "NAME": "Тестовый",
      "LAST_NAME": "Аренда",
      "EMAIL": "test_arenda@mirastroyservice.ru",
      "WORK_POSITION": "",
  },
  {
      "ID": "146",
      "NAME": "Тестовый",
      "LAST_NAME": "Бухгалтерия",
      "EMAIL": "test_account@mirastroyservice.ru",
      "WORK_POSITION": "",
  },
  {
      "ID": "147",
      "NAME": "Тестовый",
      "LAST_NAME": "Кадры",
      "EMAIL": "test_hrd@mirastroyservice.ru",
      "WORK_POSITION": "",
  },
  {
      "ID": "148",
      "NAME": "Тестовый",
      "LAST_NAME": "Лаборатория",
      "EMAIL": "test_lab@mirastroyservice.ru",
      "WORK_POSITION": "",
  },
  {
      "ID": "149",
      "NAME": "Тестовый",
      "LAST_NAME": "Логисты",
      "EMAIL": "test_log@mirastroyservice.ru",
      "WORK_POSITION": "",
  },
  {
      "ID": "150",
      "NAME": "Тестовый",
      "LAST_NAME": "Маркетинг",
      "EMAIL": "test_mar@mirastroyservice.ru",
      "WORK_POSITION": "",
  },
  {
      "ID": "151",
      "NAME": "Тестовый",
      "LAST_NAME": "Продукция",
      "EMAIL": "test_prod@mirastroyservice.ru",
      "WORK_POSITION": "",
  },
  {
      "ID": "152",
      "NAME": "Тестовый",
      "LAST_NAME": "Продукция (тендерный отдел)",
      "EMAIL": "test_prodtend@mirastroyservice.ru",
      "WORK_DEPARTMENT": "",
  },
  {
      "ID": "153",
      "NAME": "Тестовый",
      "LAST_NAME": "СБ",
      "EMAIL": "test_sbe@mirastroyservice.ru",
      "WORK_POSITION": "",
  },
  {
      "ID": "154",
      "NAME": "Тестовый",
      "LAST_NAME": "Юристы",
      "EMAIL": "test_law@mirastroyservice.ru",
      "WORK_POSITION": "",
  },
  {
    "ID": "86",
    "NAME": "Андрей",
    "LAST_NAME": "Третьяков",
    "EMAIL": "a@vessna.pro",
    "WORK_POSITION": "Внешний консультант",
  }
];

const INITIAL_STATE = {
  palette: {
    white: {main:'rgb(255,255,255)'},
    black: {main:'rgb(12,4,5)'},
    pink: {main:"rgb(248,246,247)"},
    darkPink: {main:"rgb(250,245,247)"},
    red: {main:"rgb(234,76,137)"},
    darkRed: {main:"rgb(247,9,116)"},
    deepRed: {main:"rgb(199,22,102)"},
    blue: {main:"rgb(149,223,241)"},
    yellow: {main:"rgb(253,198,0)"},
    error: {main:"red"},
    gray: {main:"rgb(200,200,200)"},
  },
  theme: createTheme({
    typography: {
      htmlFontSize: 17,
    },
  }),
  formatCrc: (crc) => { return crc.toLocaleString(undefined, { maximumFractionDigits: 2 })},
  lt_margin: 16,
  lt_gutter: 8,
  lt: calc(window.$(document).width(), 16, 8),
  rest: "https://mirastroyservice.ru/rest/",
  //api: "https://mirastroyservice.ru/rest/86/qcw76br0jmg1ikeb/",
  restApiKey:"",
  docTypesData:null,
  partners: null,
  signers: null,
  crc: null,
  parents:null,
  initiators:null,
  workers:null,
  deals:[],
  archivedDeals:[],
  dealBlockInfoData:{},
  auth:null,
  currentUser:null,
  isUserSB:false,
  testUsers: testUsers,
  isTest:false,
  isProduction: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? false : true,
  workerDeals:[],
  initiatorDeals:[],
  signerDeals:[],
  logDeals:[],
  cachedLogs: [],
  userInfos: [],
  groupInfos: [],
};

  /*isUserInitiator:false,
  isUserWorker:false,
  isUserSigner:false,
  isUserInLogs:false,*/

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CALCULATED_LAYOUT:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case GET_DOC_TYPES:
      return {
        ...state,
        ...action.payload,
      };
    case GET_PARTNERS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_SIGNERS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_CRC:
      return {
        ...state,
        ...action.payload,
      };
    case GET_PARENT_DOCS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_INITIATOR:
      return {
        ...state,
        ...action.payload,
      };
    case GET_WORKER:
      return {
        ...state,
        ...action.payload,
      };
    case SET_DEAL:
      return {
        ...state,
        ...action.payload,
      };
    case GET_DEALS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_CURRENT_USER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return INITIAL_STATE;
  }
};