import './App.css';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Stack, StackItem, Axis, ContentContainer, Dbg, Debug } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage, Title } from './Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Card, CircularProgress, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from "@emotion/styled/macro";
import axios from 'axios';
import _ from 'lodash';
import { series } from 'async';
import { ThemeProvider } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { Color } from './flatColors';
import { 
  getDeal,
  setDeal,
  prepUrl,
  addReviewMessage,
  getLogs
} from "./redux/actions";
import { getPropValue } from './helpers';
import uuid from 'react-uuid';

function FileList(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const $ = window.$;
  const [errMessage, setErrMessage] = useState('');
  const [files, setFiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [activeFile, setActiveFile] = useState("");
  const currentUser = stateObj.currentUser;
  const workerDeals = stateObj.workerDeals;
  const initiatorDeals = stateObj.initiatorDeals;
  const [isUserInitiator, setIsUserInitiator] = useState(false);
  const [isUserWorker, setIsUserWorker] = useState(false);
  const [approvesByUrist, setApprovedByUrist] = useState(false);

  useEffect(()=>{
    dispatch(getDeal(props.docId, (deal)=>{
      let f = _.find(workerDeals, x => x.ID === props.docId);
      if (f) {
        setIsUserWorker(true);
      }
      f = _.find(initiatorDeals, x => x.ID === props.docId);
      if (f) {
        setIsUserInitiator(true);
      }
      let logs = getPropValue(deal, "PROPERTY_311", -1);
      logs = logs === null ? [] : logs;
      dispatch(getLogs(logs, (data)=>{
        _.forEach(data, (d)=>{
          if (d.hasOwnProperty("GROUP_INFO") && d.GROUP_INFO.NAME === "Юристы") {
            let v = getPropValue(d, "PROPERTY_307");
            //console.log(v);
            if (v === "Согласовано") {
              setApprovedByUrist(true);
              return false;
            }
          }
        })
        //setCheckLogs(data);
      }));
      let af = getPropValue(deal, "PROPERTY_327");
      setActiveFile(af);
      listFiles();
    }));
  },[]); //props.docId

  function listFiles() {
    let blockId;
    let fileProperty;
    if (props.docId) {
      if (props.type === "deal") {
        blockId = 60;
        fileProperty = "PROPERTY_319";
      } else if (props.type === "check") {
        blockId = 61;
        fileProperty = "PROPERTY_320";
      } else if (props.type === "deal-scan") {
        blockId = 60;
        fileProperty = "PROPERTY_321";
      }
      let p = prepUrl(stateObj.rest, "lists.element.get", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": blockId, "SOCNET_GROUP_ID":71, "FILTER": {"=ID":props.docId} });
      axios({url: p.url, method: 'post', data: p.params}).then((response)=>{
        let data = response.data.result[0];
        //console.log(data, fileProperty);
        if (data.hasOwnProperty(fileProperty)) {
          let keys = Object.keys(data[fileProperty]);
          let fs = [];
          let funcs = [];
          _.forEach(keys, (key)=>{
            _.forEach(data[fileProperty][key], (f)=>{
              funcs.push(function(callback) {
                let p = prepUrl(stateObj.rest, "disk.attachedObject.get", { "id":f });
                p.url += "/?id=" + f;
                if (p.params.hasOwnProperty("auth")) {
                  p.url += "&auth="+p.params.auth;
                }
                //console.log(p);
                axios({url:"https://deals.mirastroy.ru/api.php", method:"post", data:{command:"call", url:p.url, params:{}}}).then((response)=>{
                  let fileMeta = response.data.result;
                  //console.log(response );
                  let d = new Date(fileMeta.CREATE_TIME);
                  let yyyy = d.getFullYear();
                  let m = `0${d.getMonth()+1}`.substr(-2);
                  let dd = `0${d.getDate()}`.substr(-2);
                  callback(null, {id:fileMeta.ID, name:fileMeta.NAME, date: `${dd}.${m}.${yyyy}`, url:fileMeta.DOWNLOAD_URL});
                }).catch((response)=>{
                  let p = prepUrl(stateObj.rest, "disk.file.get", { "id":f });
                  axios({url:"https://deals.mirastroy.ru/api.php", method:"post", data:{command:"call", url:p.url, params:p.params}}).then((response)=>{
                    let fileMeta = response.data.result;
                    //console.log(fileMeta);
                    let d = new Date(fileMeta.CREATE_TIME);
                    let yyyy = d.getFullYear();
                    let m = `0${d.getMonth()+1}`.substr(-2);
                    let dd = `0${d.getDate()}`.substr(-2);
                    callback(null, {id:fileMeta.ID, name:fileMeta.NAME, date: `${dd}.${m}.${yyyy}`, url:fileMeta.DOWNLOAD_URL});
                  }).catch((response)=>{
                    setLoading(false);
                  })
                });
                
                /*axios.get(p.url).then((response)=>{
                  let fileMeta = response.data.result;
                  //console.log(response );
                  let d = new Date(fileMeta.CREATE_TIME);
                  let yyyy = d.getFullYear();
                  let m = `0${d.getMonth()+1}`.substr(-2);
                  let dd = `0${d.getDate()}`.substr(-2);
                  callback(null, {id:fileMeta.ID, name:fileMeta.NAME, date: `${dd}.${m}.${yyyy}`, url:fileMeta.DOWNLOAD_URL});
                //axios.post({url:_url, method:"post", data: {}}).then((response)=>{
                }).catch((response)=>{
                  //console.log(response);
                  let p = prepUrl(stateObj.rest, "disk.file.get", { "id":f });
                  axios({url: p.url, method: 'post', data: p.params}).then((response)=>{
                    let fileMeta = response.data.result;
                    //console.log(fileMeta);
                    let d = new Date(fileMeta.CREATE_TIME);
                    let yyyy = d.getFullYear();
                    let m = `0${d.getMonth()+1}`.substr(-2);
                    let dd = `0${d.getDate()}`.substr(-2);
                    callback(null, {id:fileMeta.ID, name:fileMeta.NAME, date: `${dd}.${m}.${yyyy}`, url:fileMeta.DOWNLOAD_URL});
                  }).catch((response)=>{
                    setLoading(false);
                    //console.log(response);
                  })
                })*/
              });              
            })
          });
          series(funcs, function(err, results) {
            setLoading(false);
            setFiles(results);
          });
        } else {
          setLoading(false);
          setFiles(null);
        }
      }).catch((error => {
        console.log(error);
      }))
    }
  }

  const onFileChange = (e)=>{
    //setErrMessage('');
    //console.log(props.docId);
    if (!props.docId) {
      setErrMessage("Чтобы добавить файлы, сохраните документ");
      $("#file-upload")[0].value = '';
      setTimeout(()=>{
        setErrMessage('');
      },3000)
      return;
    }
    let selectedFile = e.target.files[0];
    //setSelectedFile(e.target.files[0]);
    const formData = new FormData();
    // Update the formData object
    formData.append(
      "myFile",
      selectedFile,
      selectedFile.name
    );
    formData.append("docId", props.docId);
    let url = "https://deals.mirastroy.ru/upload.php"; //"https://deals.mirastroy.ru/upload.php"
    let random_string = makeid(5);
    if (props.type === "deal") {
      formData.append("blockId", 60);
      formData.append("random_string", random_string);
      formData.append("fileProperty", "PROPERTY_319");
    } else if (props.type === "check") {
      formData.append("blockId", 61);
      formData.append("random_string", random_string);
      formData.append("fileProperty", "PROPERTY_320");
    } else if (props.type === "deal-scan") {
      formData.append("blockId", 60);
      formData.append("random_string", random_string);
      formData.append("fileProperty", "PROPERTY_321");
    }
    setLoading(true);
    axios.post(url, formData, {maxBodyLength: Infinity, maxContentLength: Infinity, onUploadProgress: (e)=>{     
      let percent = Math.floor((100 * e.loaded) /e.total);
      setPercent(percent);
      //console.log('Percent', percent)
    }}).then((result)=>{
      if (result.status === 200) {
        if (files === null || files.length === 0) {
          let fn;
          if (selectedFile.name.lastIndexOf('.') !== -1) {
            let ex = selectedFile.name.substr(selectedFile.name.lastIndexOf('.') + 1);
            let f = selectedFile.name.substring(0, selectedFile.name.lastIndexOf('.'));
            fn = f + "-" + random_string + "." + ex;
          } else {
            fn = selectedFile.name
          }
          let fields = {
            "PROPERTY_327": {
              "0": fn
            },
          };
          dispatch(setDeal(props.docId, fields, (id)=>{ 
            setActiveFile(fn);
            setLoading(false);
            listFiles();
            console.log(fn);
          }));
        } else {
          setLoading(false);
          listFiles();
        }
        $("#file-upload")[0].value = '';
      }
    });
  }

  let footerHeight = 0;
  if (props.allowUploadFiles) {
    footerHeight = 60;
  }

  const handleDeleteFile = (e)=>{
    try {
      let fileId = $(e.target).closest("td").attr("data-rec");
      if (props.docId) {
        let blockId;
        let fileProperty; 
        if (props.type === "deal") {
          blockId = 60;
          fileProperty = "PROPERTY_319"; 
        } else if (props.type === "check") {
          blockId = 61;
          fileProperty = "PROPERTY_320"; 
        } else if (props.type === "deal-scan") {
          blockId = 60;
          fileProperty = "PROPERTY_321"; 
        }
        let p = prepUrl(stateObj.rest, "lists.element.get", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": blockId, "SOCNET_GROUP_ID":71, "FILTER": {"=ID":props.docId} });
        setLoading(true);
        axios({url: p.url, method: 'post', data: p.params}).then((response)=>{
          let data = response.data.result[0];
          if (data.hasOwnProperty(fileProperty)) {
            let keys = Object.keys(data[fileProperty]);
            _.forEach(keys, (key)=>{
              let cp = [];
              _.forEach(data[fileProperty][key], (f, i)=>{
                if (fileId !== f) {
                  cp.push(f);
                }            
              });
              data[fileProperty][key] = [...cp];
            });
            if (data[fileProperty].length === 0) {
              delete data[fileProperty];
            }
            let p = prepUrl(stateObj.rest, "lists.element.update", { "IBLOCK_TYPE_ID":"lists_socnet", "IBLOCK_ID": blockId, "SOCNET_GROUP_ID":71, "ELEMENT_ID": props.docId, "FIELDS":data });
            axios({url: p.url, method: 'post', data: p.params}).then((response)=>{
              listFiles();
            }).catch((error)=>{
              setLoading(false);
              console.log(error);
            })
          }
        }).catch((error)=>{
          console.log(error);
        })
      }
    } catch(e) {

    }
  }

  const handleIndicatorClick = (e) => {
    let file = $(e.target).closest(".ind").attr("data-id")
    let fields = {
      "PROPERTY_327": {
        "0": file
      },
      "PROPERTY_326": {
        "0": new Date()
      },
    };
    dispatch(setDeal(props.docId, fields, (id)=>{ 
      let newMsg = {id:uuid(), date:new Date(), text: "Указан новый окончательный файл. Согласования обнуляются.", user:currentUser.NAME+" "+currentUser.LAST_NAME, user_id:currentUser.ID}; 
      dispatch(addReviewMessage(props.docId, newMsg, (data)=>{ 
        setActiveFile(file);
        //listFiles();
      }));
    }));
  }

  return(
  <ThemeProvider theme={stateObj.theme}>
    <Column width='100%'>
      <Row height={35}><Subtitle1>{props.title}</Subtitle1></Row>
      <Card>
        <TableContainer sx={{ maxHeight: props.height-35-footerHeight }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TD align="left" sx={{backgroundColor:"#eaeefa"}}><Header4>Статус</Header4></TD>
                <TD align="left" sx={{backgroundColor:"#eaeefa"}}><Header4>Наименование</Header4></TD>
                <TD align="left" sx={{backgroundColor:"#eaeefa"}}><Header4>Дата</Header4></TD>
                {props.allowDeleteFiles && 
                  <TD align="center" sx={{backgroundColor:"#eaeefa"}}><Header4>Удалить</Header4></TD>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              { files && files.map((file, i)=>{
                let indColor = Color.flatGray;
                //console.log(file.name, activeFile)
                let showU = null;
                if (file.name === activeFile) {
                  indColor = Color.flatGreen;
                  if (approvesByUrist) {
                    showU = Color.flatGreen;
                  } else {
                    showU = Color.flatGray;
                  }
                } else {
                  indColor = Color.flatGray;
                }
                return(
                  <TableRow key={file.id }>
                    <TD>
                      <Row>
                        { isUserInitiator || isUserWorker ?
                          <div className="ind" data-id={file.name}><FileIndicator color={indColor} text="И" onClick={handleIndicatorClick}></FileIndicator></div>
                        :
                          <div data-id={file.name}><FileIndicator color={indColor} text="И"></FileIndicator></div>
                        }
                        <Row width={3}></Row>
                        { showU !== null &&
                          <div data-id={file.name}><FileIndicator color={showU} text="Ю"></FileIndicator></div>
                        }
                      </Row>
                    </TD>
                    <TD><Tooltip title={file.name}><a href={file.url}><DocName>{file.name}</DocName></a></Tooltip></TD>  
                    <TD>{file.date}</TD>  
                    {props.allowDeleteFiles && 
                      <TD data-rec={file.id}>
                        <Row main={Axis.main.center}>
                          <IconButton data-rec={file.id} size="large" color='primary' onClick={handleDeleteFile}><DeleteIcon /></IconButton>
                        </Row>
                      </TD>
                    }
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        { props.allowUploadFiles &&
          <Row height={footerHeight} main={Axis.main.start} cross={Axis.cross.center} color="#f1f3fc">
            <Column width='100%'>
              <Row left={8}>
                <LabelFileUpload htmlFor="file-upload"><Row height={44} top={11} left={12} right={12}><Header5 color="white">{files && files.length > 0 ? "Загрузить еще" : "Загрузить файл"}</Header5></Row></LabelFileUpload>
                <input id="file-upload" type="file" style={{display:"none"}} onChange={onFileChange} />
                <Row left={16}>
                { loading &&
                  <Row cross={Axis.cross.center}>
                    <CircularProgress></CircularProgress>
                    <Row left={16}>{percent > 0 ? percent+"%": ""}</Row>
                  </Row>
                }
                </Row>
              </Row>
            </Column>
            <Column>
              <Row left={16}><Body1 color='red'>{errMessage}</Body1></Row>
            </Column>
          </Row>
        }
      </Card>
      
    </Column>
  </ThemeProvider>
  )
}

const LabelFileUpload = styled('label')`
  border: 0px solid #ccc;
  background-color:#1976d2;
  display: block;
  cursor: pointer;
  height:42px;
`;

const DocName = styled('p')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
`;

const TD = styled(TableCell)`
  padding:0px;
  padding-top:8px;
  padding-bottom:8px;
  padding-left:16px;
  padding-right:8px;
`;

function FileIndicator(props) {
  return(
    <Row className='indicator' onClick={props.onClick} color={props.color} width={17} height={17} sx={{textAlign:"center", borderRadius:17/2, paddingTop:0, fontSize:9, fontWeight:500, color:"white"}} center><Row>{props.text}</Row></Row>
  )
}

function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export default FileList;