export const SET_CALCULATED_LAYOUT = "SET_CALCULATED_LAYOUT";
export const SET_ERROR = "SET_ERROR";
export const RESET_STATE = "RESET_STATE";
export const GET_DOC_TYPES = "GET_DOC_TYPES";
export const GET_PARTNERS = "GET_PARTNERS";
export const GET_PARENT_DOCS = "GET_PARENT_DOCS";
export const GET_SIGNERS = "GET_SIGNERS";
export const GET_CRC = "GET_CRC";
export const GET_INITIATOR = "GET_INITIATOR"; //Иницатор
export const GET_WORKER = "GET_WORKER"; //Исполнитель
export const SET_DEAL = "SET_DEAL";
export const GET_DEALS = "GET_DEALS";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
