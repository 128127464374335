import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Stack, StackItem, Axis, ContentContainer, Dbg, Debug } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage, Title } from './Typography';
import _ from 'lodash';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FileList from './FlieList';
import LinearProgress from '@mui/material/LinearProgress';
import { Card, CardContent } from '@mui/material';
import ReviewTextBlock from './ReviewTextBlock';
import dayjs from 'dayjs';

import { 
    resetState,
    updateCalculatedLayout,
    getDeal,
    getPartner,
    getInitiator,
    getWorker,
    setCheck,
    getCheck
} from "./redux/actions";
import ToolBar from './ToolBar';
import { getPropValue } from './helpers';

function CheckPartnerPage(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const location = useLocation();
  const navigate = useNavigate();
  const docId = location.state.docId;
  const checkId = location.state.checkId;
  const lt = stateObj.lt;
  const currentUser = stateObj.currentUser;
  const $ = window.$;
  const [partnerValue, setPartnerValue] = React.useState('');
  const [nameDocValue, setNameDoc] = React.useState('');
  const [initiatorValue, setInitiator] = React.useState('');
  const [workerValue, setWorker] = React.useState('');
  const [commentValue, setComment] = React.useState('');
  const [noteValue, setNote] = React.useState('');
  const [fileListHeight, setFileListHeight] = React.useState(400);
  //const [checkDocId, setCheckDocId] = React.useState(null);
  const [errMessage, setErrMessage] = useState([]);
  const [urlPartner, setUrlPartner] = useState("#");
  const [loading, setLoading] = useState(false);
  const [detailText, setDetailText] = useState('');

  const notes = [
    {id:1, name:"Без замечаний"},
    {id:2, name:"С замечаниями"},
  ];

  //console.log(docId, checkId);

  useEffect(()=>{
    setLoading(true);
    dispatch(getDeal(docId, (data)=>{
      //console.log(data);
      setDetailText(data.DETAIL_TEXT);
      dispatch(getCheck(checkId, (data)=>{
        setLoading(false);
        setComment(getPropValue(data, "PROPERTY_283") ? getPropValue(data, "PROPERTY_283").TEXT : null);
        let n = getPropValue(data, "PROPERTY_281");
        //console.log(n);
        if (n) {
          let noteId = _.find(notes, x => x.name === n).id;
          setNote(noteId);
        }
      }));
      setNameDoc(data.NAME);
      dispatch(getPartner(getPropValue(data, "PROPERTY_288"), (res)=>{
        setPartnerValue(res);
        setUrlPartner(`https://mirastroyservice.ru/crm/company/details/${res.ID}/`);
      }));
      dispatch(getInitiator(getPropValue(data, "PROPERTY_297"), (res)=>{
        setInitiator(res);
      }));
      dispatch(getWorker(getPropValue(data, "PROPERTY_312"), (res)=>{
        setWorker(res)
      }));
    }));
  },[]);

  const handleComment = (event) => {
    setComment(event.target.value);
  }

  const handleSave = () => {
    let e = validate();
    if (e) {
      setErrMessage(e);
      return;
    }
    
    saveDoc(()=>{

    });
  }

  function saveDoc(callback) {
    let dateValue = dayjs(new Date());
    let yyyy = dateValue.$y;
    let m = `0${dateValue.$M+1}`.substr(-2);
    let d = `0${dateValue.$D}`.substr(-2);
    let nameDoc = `${partnerValue.TITLE}, ${d}.${m}.${yyyy}`;

    let note = _.find(notes, (x)=>{ return x.id === noteValue}).name;
    //console.log(currentUser);
    
    let fields = {
      "NAME": nameDoc,
      "PROPERTY_278": {
        "0": partnerValue.ID
      },
      "PROPERTY_279": {
        "0":docId 
      },
      "PROPERTY_281": {
        "0": note
      },
      "PROPERTY_283": {
        "0": {
          "TEXT":commentValue
        }
      },
      "PROPERTY_284": {
        "0": currentUser.ID.toString()
      },
    }

    if (!commentValue) {
      delete fields.PROPERTY_283;
    }

    //console.log(fields);
    dispatch(setCheck(checkId, fields, (id)=>{
      callback(id);
    }));
  }

  function validate() {
    let e = [];
    setErrMessage(e);
    if (!noteValue) {
      e.push("Выберите заключение");
    }
    if (e.length === 0) {
      return null;
    } else {
      return e;
    }
  }

  const handleNote = (e) => {
    setNote(e.target.value);
  }

  const handleToolBarSend = ()=>{
    let e = validate();
    if (e) {
      setErrMessage(e);
      return;
    }

    saveDoc((id)=>{
      let dateValue = dayjs(new Date());
      let yyyy = dateValue.$y;
      let m = `0${dateValue.$M+1}`.substr(-2);
      let d = `0${dateValue.$D}`.substr(-2);
      
      let fields = {
        "PROPERTY_280": {
          "0":`${d}.${m}.${yyyy}` 
        },
      }

      //console.log(fields);
      dispatch(setCheck(checkId, fields, (id)=>{
        navigate('/', { state: {} });
      }));
    })
  }

  const handleRead = (e)=>{
    navigate('/read',  {state:{docId: docId, hideToolBar:true}});
  }

  const handleGotoCRM = (e) => {
    window.open(urlPartner, '_blank');
  }
  
  return (
    <Column left={24} right={24}>
      <Row top={8}><ToolBar state="check" handleSave={handleSave} handleSend={handleToolBarSend} titleForSendDialog="Отправить документ?"></ToolBar ></Row>
      {errMessage.map((e)=>{
          return(<Row key={e}><Body1 color='red'>{e}</Body1></Row>)
      })}
      { loading &&
      <LinearProgress />
      }
      <Row top={32} bottom={16}>
        <Header1>Проверка контагента {checkId} (ID: {docId})</Header1>
      </Row>
      <Row>
        <Column>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell><Subtitle1>Контрагент в CRM:</Subtitle1></TableCell >
                  <TableCell><a href="javascript:void(0);" onClick={handleGotoCRM}>{partnerValue ? partnerValue.TITLE : ""}</a></TableCell >
                </TableRow>
                <TableRow>
                  <TableCell><Subtitle1>Согласование:</Subtitle1></TableCell >
                  <TableCell><a href="javascript:void(0);" onClick={handleRead}>{nameDocValue ? nameDocValue : ""}</a></TableCell >
                </TableRow>
                <TableRow>
                  <TableCell><Subtitle1>Инициатор:</Subtitle1></TableCell >
                  <TableCell>{initiatorValue ? `${initiatorValue.NAME} ${initiatorValue.LAST_NAME} - ${initiatorValue.WORK_POSITION}` : ""}</TableCell >
                </TableRow>
                <TableRow>
                  <TableCell><Subtitle1>Исполнитель:</Subtitle1></TableCell >
                  <TableCell>{workerValue ? `${workerValue.NAME} ${workerValue.LAST_NAME} - ${workerValue.WORK_POSITION}` : ""}</TableCell >
                </TableRow>
                <TableRow>
                  <TableCell><Subtitle1>Комментарий:</Subtitle1></TableCell >
                  <TableCell>{detailText ? detailText : ""}</TableCell >
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Row>
            <Column top={36} width={1000}>
              <Row width="100%">
                <FormControl fullWidth>
                  <InputLabel id="select-notes-label">Заключение</InputLabel>
                  <Select
                    labelId="select-notes-label"
                    id="select-notes"
                    value={noteValue} 
                    label="Заключение"
                    onChange={handleNote}
                  >
                    {notes.map((item)=>{
                      return(<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Row>
              <Row>
                <Row top={24} width="calc(100%)">
                  <TextField
                    fullWidth
                    id="comment-doc"
                    label="Комментарий"
                    multiline
                    rows={10}
                    value={!commentValue ? "" : commentValue}
                    onChange={handleComment}
                  />
                </Row>
              </Row>
            </Column>
          </Row>
        </Column>
        <Column  left={16} top={0} width={500}>
          <Row left={0} bottom={10}><FileList type="check" height={fileListHeight} title="Файлы проверки:" allowUploadFiles={true} allowDeleteFiles={true} docId={checkId}></FileList></Row>
          <Card>
            <ReviewTextBlock docId={docId} allowSend={true}></ReviewTextBlock>
          </Card>
        </Column>
      </Row>
    </Column>
  );
}
  
export default CheckPartnerPage;
  



