import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Stack, StackItem, Axis, ContentContainer, Dbg, Debug } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage, Title } from './Typography';
import IconButton from '@mui/material/IconButton';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

function ToolBar(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const $ = window.$;
  const navigate = useNavigate();
  let state;
  const [openDialog, setDialogOpen] = React.useState(false);
  const [openDialogSend, setDialogOpenSend] = React.useState(false);

  /*const handleDialogOpen = () => {
    setDialogOpen(true);
  };*/

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSendClose = () => {
    setDialogOpenSend(false);
  };

  if (props.state === 'new') {
    state = {new:true, process:true, archive:true, save:false, remove:false,close:false,send:false,settings:false};
  } else if (props.state === 'check') {
    state = {new:true, process:true, archive:true, save:false, remove:true,close:false,send:false,settings:false};
  } else if (props.state === 'process') {
    state = {new:false, process:true, archive:false, save:true, remove:true,close:true,send:true,settings:false};
  } else if (props.state === 'archive') {
    state = {new:true, process:false, archive:true, save:true, remove:true,close:true,send:true,settings:false};
  } else if (props.state === 'settings') {
    state = {new:false, process:false, archive:false, save:true, remove:true,close:true,send:true,settings:true};
  } else if (props.state === 'approval') {
    state = {new:true, process:true, archive:true, save:true, remove:true,close:false,send:true,settings:false};
  } else if (props.state === 'sign') {
    state = {new:true, process:true, archive:true, save:false, remove:true,close:false,send:true,settings:false};
  } else if (props.state === 'read') {
    state = {new:true, process:true, archive:true, save:true, remove:true,close:false,send:true,settings:false};
  }

  if (props.buttons) {
    state = props.buttons;
  }

  const handleNewDeal = (e)=>{
    navigate('/new', { state: {} });
  }

  const handleClose = (e)=>{
    if (props.onCloseNavigate) {
      navigate(props.onCloseNavigate);
    } else {
      navigate('/', { state: {} });
    }
  }

  const handleRemove = (e)=>{
    setDialogOpen(true);
  }

  const handleCancel = (e)=>{
    setDialogOpen(false);
  }

  const handleAgree = (e)=>{
    props.handleRemove();
  }

  const handleSend = (e)=>{
    setDialogOpenSend(true);
  }

  const handleCancelSend = (e)=>{
    setDialogOpenSend(false);
  }

  const handleAgreeSend = (e)=>{
    props.handleSend();
  }

  const handleArchive = (e)=>{
    navigate('/archive', { state: {} });
  }

  const handleInProcess = (e)=>{
    navigate('/', { state: {} });
  }

  const handleSettings = (e)=>{
    //navigate('/settings', { state: {} });
    window.open("https://mirastroyservice.ru/workgroups/group/71/lists/", "_blank");
  }

  return(
    <Column width='100%'>
      <Row main={Axis.main.between} >
        <Row cross={Axis.cross.center}>
          <Tooltip title="Новое">
            <div>
              <IconButton size="large" color='primary' disabled={state.new} onClick={handleNewDeal}>
                <InsertDriveFileOutlinedIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Row height={30} width={1} left={3} right={3} color='rgba(0,0,0,0.2)'></Row>
          <Tooltip title="В работе">
            <div>
              <IconButton size="large" color='primary' disabled={state.process} onClick={handleInProcess}>
                <FolderOpenOutlinedIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title="Архив">
            <div>
              <IconButton size="large" color='primary' disabled={state.archive} onClick={handleArchive}>
                <ArchiveOutlinedIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Row height={30} width={1} left={3} right={3} color='rgba(0,0,0,0.2)'></Row>
          <Tooltip title="Сохранить">
            <div>
              <IconButton size="large" color='primary' disabled={state.save} onClick={props.handleSave}>
                <SaveOutlinedIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title="Удалить">
            <div>
              <IconButton size="large" color='primary' disabled={state.remove} onClick={handleRemove}>
                <DeleteIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title="Закрыть">
            <div>
              <IconButton size="large" color='primary' disabled={state.close} onClick={handleClose}>
                <CloseOutlinedIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title="Отправить">
            <div>
              <IconButton size="large" color='primary' disabled={state.send} onClick={handleSend}>
                <SendOutlinedIcon />
              </IconButton>
            </div>
          </Tooltip>
        </Row>
        <Row right={16}>
          <Tooltip title="Настройки">
            <div>
              <IconButton size="large" color='primary' disabled={state.settings} onClick={handleSettings}>
                <SettingsIcon />
              </IconButton>
            </div>
          </Tooltip>
        </Row>
      </Row>
      <Row color='rgba(0,0,0,0.1)'width="100%" height={1} top={6} bottom={0}></Row>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Подтверждение"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Вы действительно хотите удалить документ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAgree}>Да</Button>
          <Button onClick={handleCancel} autoFocus>Нет</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogSend}
        onClose={handleDialogSendClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-send">
          {"Подтверждение"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="send-dialog-description">
            {props.titleForSendDialog}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAgreeSend}>Да</Button>
          <Button onClick={handleCancelSend} autoFocus>Нет</Button>
        </DialogActions>
      </Dialog>
    </Column>
  )
}

export default ToolBar;