import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Stack, StackItem, Axis, ContentContainer, Dbg, Debug } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage, Title } from './Typography';
import _ from 'lodash';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';

import { 
    resetState,
    updateCalculatedLayout
} from "./redux/actions";
import ToolBar from './ToolBar';

function SettingsPage(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const $ = window.$;

  useEffect(()=>{
    
  },[]);

  
  return (
    <Column left={24} right={24}>
      <Row top={8}><ToolBar state="settings"></ToolBar ></Row>
      <Row top={32} bottom={16}>
        <Header1>Настройки</Header1>
      </Row>
      <Column>
        
      </Column>
    </Column>
  );
}
  
export default SettingsPage;
  



