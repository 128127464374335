import styled from '@emotion/styled';

export const Header1 = styled.h1(props => ({
  fontWeight:500,
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Header2 = styled.h2(props => ({
  //fontFamily:'freight-big-pro', 
  //fontSize:'calc(1.635rem + .9vw)',
  fontWeight:500,
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Header3 = styled.h3(props => ({
  //fontFamily:'freight-big-pro', 
  //fontSize:'calc(1.4rem + .6vw)',
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Header4 = styled.h4(props => ({
  //fontFamily:'freight-big-pro', 
  //fontSize:'calc(1.275rem + .3vw)',
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Header5 = styled.h5(props => ({
  //fontFamily:'freight-big-pro', 
  //fontSize:'1.25rem',
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Header6 = styled.h6(props => ({
  //fontFamily:'freight-big-pro', 
  //fontSize:'1rem',
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Subtitle1 = styled.p(props => ({
  /*fontFamily:'supria-sans', 
  fontWeight:600,
  fontSize:'1.2rem',
  lineHeight:'1.3em',
  fontKerning: 'auto',*/
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const ErrorMessage = styled.p(props => ({
  color:'red',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Subtitle2 = styled.p(props => ({
  /*fontFamily:'supria-sans', 
  fontWeight:500,
  fontSize:'1.2rem',
  lineHeight:'1.3em',
  fontKerning: 'auto',*/
  fontWeight:500,
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Body1 = styled.div(props => ({
  /*fontFamily:'supria-sans', 
  fontWeight:400,
  fontSize:'1rem',*/
  fontSize:'0.9rem',
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Body2 = styled.div(props => ({
  /*fontFamily:'supria-sans', 
  fontWeight:300,
  fontSize:'1rem',*/
  fontWeight:500,
  fontSize:'0.9rem',
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

export const Caption = styled.div(props => ({
  /*fontFamily:'supria-sans', 
  fontWeight:400,
  fontSize:'0.8rem',*/
  color: props.color ? props.color : 'inherit',
  marginTop:props.top ? props.top : '0px',
  marginBottom:props.bottom ? props.bottom : '0px',
  marginLeft:props.left ? props.left : '0px',
  marginRight:props.right ? props.right : '0px',
}));

