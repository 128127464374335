import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import store from "./redux/store";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NewDealPage from './NewDealPage';
import ArchivePage from './ArchivePage';
import SettingsPage from './SettingsPage';
import CheckPartnerPage from './CheckPartnerPage';
import DealApprovalPage from './DealApprovalPage';
import DealSigningPage from './DealSigningPage';
import DealReadingPage from './DealReadingPage';
import DealAnswerPage from './DealAnswerPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router basename={''}>
      <Routes>
        <Route exact path="/" element={
          <App />
        } />
        <Route exact path="/new" element={
          <NewDealPage />
        } />
        <Route exact path="/archive" element={
          <ArchivePage />
        } />
        <Route exact path="/check" element={
          <CheckPartnerPage />
        } />
        <Route exact path="/approval" element={
          <DealApprovalPage />
        } />
        <Route exact path="/sign" element={
          <DealSigningPage />
        } />
        <Route exact path="/read" element={
          <DealReadingPage />
        } />
        <Route exact path="/answer" element={
          <DealAnswerPage />
        } />
        <Route exact path="/settings" element={
          <SettingsPage />
        } />
      </Routes>
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
