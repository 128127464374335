import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import InProcessPage from './InProcessPage';
import { 
  resetState,
} from "./redux/actions";

function App() {
  /*const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const $ = window.$;

  useEffect(()=>{
    let auth = $("#auth").val();
    dispatch(resetState({auth:auth}));
  },[]);*/

  return (
    <InProcessPage></InProcessPage>
  );
}

export default App;
