import _ from 'lodash';

export function getPropValue(obj, propName, index=0) {
  try {
    let keys = Object.keys(obj[propName]);
    let values = [];
    if (index < 0) {
      _.forEach(keys, (key)=>{
        values.push(obj[propName][key]);
      });
      if (index === -1) {
        return values;
      } else if (index === -99) {
        return values.join("");
      } else {
        return [];
      }
    } else {
      if (keys) {
        let key = keys[index];
        return obj[propName][key];
      } else {
        return null;
      }
    }
  } catch(e) {
    return null;
  }
}