import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import {Link, useNavigate} from 'react-router-dom';
import { Row, Column, Stack, StackItem, Axis, ContentContainer, Dbg, Debug, getMatches } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage, Title } from './Typography';
import _, { ceil, chunk, findIndex } from 'lodash';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import AdjustIcon from '@mui/icons-material/Adjust';
import DeblurIcon from '@mui/icons-material/Deblur';
import BlurOffIcon from '@mui/icons-material/BlurOff';
import DrawIcon from '@mui/icons-material/Draw';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import PreviewIcon from '@mui/icons-material/Preview';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';

import { 
    resetState,
    updateCalculatedLayout,
    getDeals,
    getCurrentUser,
    getGroups,
    selectSigners,
    getSigner,
    getSigners,
    getLogs
} from "./redux/actions";
import ToolBar from './ToolBar';
import { IconButton } from '@mui/material';
import { getPropValue } from './helpers';
import uuid from 'react-uuid';
import { parallel, series, waterfall } from 'async';

function InProcessPage(props) {
    const dispatch = useDispatch();
    const stateObj = useSelector((state) => state.mainState);
    const lt = stateObj.lt;
    const deals_in_process = stateObj.deals;
    const signers = stateObj.signers;
    const currentUser = stateObj.currentUser;
    const $ = window.$;
    const navigate = useNavigate();
    const [sb, setSb] = useState(false);
    const [isUserInitiator, setIsUserInitiator] = useState(false);
    const [isUserWorker, setIsUserWorker] = useState(false);
    const [isUserSigner, setIsUserSigner] = useState(false);
    const [isUserInLogs, setIsUserInLogs] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = React.useState('');
    const [searchToken, setSearchToken] = React.useState('');
    const [loginUser, setUsetLogin] = useState(""); //для тестирования
    const [loaded, setLoaded] = useState(false); //enable Search button
    let temp_deals = [];
  
    useEffect(()=>{
      //console.log(stateObj.isProduction);
        dispatch(resetState({restApiKey:process.env.REACT_APP_REST_API_KEY}));
        temp_deals = [];
        setLoading(true);
        setLoaded(false);
        dispatch(getDeals(true, (deals)=>{
          console.log(deals.length);
          setLoading(false);
          if (!currentUser) {
            dispatch(getCurrentUser(loginUser, (user)=>{
              let funcs = [];
              _.forEach(_.chunk(_.orderBy(deals, ["ID"], ["desc"]), deals.length), (chunk, index)=>{
                funcs.push(function(callback){
                  generateDealList(deals.length, chunk, user, (allDeals)=>{
                    if (index === 0) {
                      setLoading(false);
                    }
                    callback(null, allDeals);
                  });
                })
              });
              series(funcs, (err, results)=>{
                console.log("loaded", temp_deals.length);
                setLoading(false);
                setLoaded(true);
                //console.log(_.flatten(results));
              })
            }));
          } else {
            let funcs = [];
            _.forEach(_.chunk(_.orderBy(deals, ["ID"], ["desc"]), deals.length), (chunk, index)=>{
              funcs.push(function(callback){
                generateDealList(deals.length, chunk, currentUser, (allDeals)=>{
                  if (index === 0) {
                    setLoading(false);
                  }
                  callback(null, allDeals);
                });
              })
            });
            series(funcs, (err, results)=>{
              console.log("loaded", temp_deals.length);
              setLoading(false);
              setLoaded(true);
              //console.log(_.flatten(results));
            })
          }
        }));
    },[loginUser, searchText]); 

    function generateDealList(count, deals, user, callback) {
      let isSB = false;
      _.forEach(user.GROUPS, (g)=>{
        if (g.NAME === "Служба безопасности") {
          setSb(true);
          isSB = true;
          dispatch(resetState({isUserSB:true}));
          return false;
        } else {
          dispatch(resetState({isUserSB:false}));
        }
      });
      let allDeals = [];
      if (isSB || user.ID === "86") {
        allDeals = deals;
      }
      let initiatorDeals = getDealsForInititor(deals, user);
      if (initiatorDeals.length > 0) {
        setIsUserInitiator(true);
        //dispatch(resetState({isUserInitiator:true})); 
        dispatch(resetState({initiatorDeals:initiatorDeals})); 
      } else {
        //dispatch(resetState({isUserInitiator:false})); 
        dispatch(resetState({initiatorDeals:[]})); 
      }
      let workerDeals = getDealsForWorker(deals, user);
      if (workerDeals.length > 0) {
        //console.log(workerDeals);
        setIsUserWorker(true);
        //dispatch(resetState({isUserWorker:true}));
        dispatch(resetState({workerDeals:workerDeals})); 
      } else {
        //dispatch(resetState({isUserWorker:false})); 
        dispatch(resetState({workerDeals:[]}));
      }
      allDeals = allDeals.concat(initiatorDeals);
      allDeals = allDeals.concat(workerDeals);
      getDealsForSigner(deals, user, (signerDeals)=>{
        if (signerDeals.length > 0) {
          allDeals = allDeals.concat(signerDeals);
          setIsUserSigner(true);
          //dispatch(resetState({isUserSigner:true})); 
          dispatch(resetState({signerDeals:signerDeals})); 
        } else {
          //dispatch(resetState({isUserSigner:false})); 
          dispatch(resetState({signerDeals:[]})); 
        }
        getDealsForUsersFromLosgs(deals, user, (logDeals)=>{
          //console.log("log deals", logDeals);
          
          if (logDeals.length > 0) {
            allDeals = allDeals.concat(logDeals);
            setIsUserInLogs(true);
            //dispatch(resetState({isUserInLogs:true})); 
            dispatch(resetState({logDeals:logDeals})); 
          } else {
            //dispatch(resetState({isUserInLogs:false})); 
            dispatch(resetState({logDeals:[]})); 
          }
          
          allDeals = _.uniqBy(allDeals, x => x.ID);
          allDeals = _.orderBy(allDeals, (x)=>{ return x.ID}, ["desc"]);
          
          temp_deals = _.uniq(temp_deals.concat(allDeals));
          temp_deals = _.orderBy(temp_deals, ['ID'], ['desc']);
          if (searchText) {
            let temp = [];
            _.forEach(temp_deals, (x)=>{
              let v = getPropValue(x, "NAME", -99);
              //console.log(v);
              if(v && v.toLowerCase().includes(searchText.toLowerCase())) {
                temp.push(x);
              }
            })
            if (temp.length > 0) {
              temp_deals = temp;
            }
          }
          dispatch(resetState({deals:temp_deals}));
          callback(temp_deals);
        });
      });
    }

    function getDealsForUsersFromLosgs(deals, user, callback) {
      let alllogs =[];
      let logIds = [];
      _.forEach(deals, (deal)=>{
        alllogs.push({"dealId": deal.ID, "logs": getPropValue(deal, "PROPERTY_311", -1) ? getPropValue(deal, "PROPERTY_311", -1) : []});
        logIds.push(getPropValue(deal, "PROPERTY_311", -1));
      });
      logIds = _.flatten(logIds);
      logIds = _.uniq(_.filter(logIds, item=>item !== null));
      dispatch(getLogs(logIds, (logsData)=>{
        //console.log(logsData);
        let d = [];
        _.forEach(logsData, (log)=>{
          let usersInGroup = getPropValue(log.GROUP_INFO, "PROPERTY_276", -1);
          let userIdInGroup = _.find(usersInGroup, x => x === user.ID);
          _.forEach(alllogs, (x) => {
            _.forEach(x.logs, (y) => {
              if (String(y) === log.ID) {
                let deal = _.find(deals, tt => tt.ID === x.dealId);
                if (userIdInGroup) {
                  if (!deal.hasOwnProperty("LOGS_DATA")) {
                    deal.LOGS_DATA = [];
                  }
                  deal.LOGS_DATA.push(log);
                  d.push(deal);
                }
              }
            })
          })
        });
        //console.log(deals);
        callback(d);
      }))
      /*let funcs = [];
      _.forEach(deals, (deal)=>{
        let logs = getPropValue(deal, "PROPERTY_311", -1);
        funcs.push((callback)=>{
          dispatch(getLogs(logs, (logsData)=>{
            //console.log(logsData);
            let d = [];
            _.forEach(logsData, (log)=>{
              //console.log(log);
              let usersInGroup = getPropValue(log.GROUP_INFO, "PROPERTY_276", -1);
              let userIdInGroup = _.find(usersInGroup, x => x === user.ID);
              if (userIdInGroup) {
                if (!deal.hasOwnProperty("LOGS_DATA")) {
                  deal.LOGS_DATA = [];
                }
                deal.LOGS_DATA.push(log);
                d.push(deal);
              }
            })
            callback(null, d);
          }))
        });
      })
      parallel(funcs, (err, results)=>{
        let flat = _.flatten(results);
        callback(flat);
      })*/
    }

    function getDealsForInititor(deals, user) {
      let newDeals = [];
      _.forEach(deals, (deal)=>{
        let p = getPropValue(deal, "PROPERTY_297");
        if (p && p === user.ID) {
          newDeals.push(deal);
        }
      })
      return newDeals;
    }

    function getDealsForWorker(deals, user) {
      let newDeals = [];
      _.forEach(deals, (deal)=>{
        let p = getPropValue(deal, "PROPERTY_312");
        if (p && p === user.ID) {
          newDeals.push(deal);
        }
      })
      return newDeals;
    }

    function getDealsForSigner(deals, user, callback) {
      let newDeals = [];
      if (!signers) {
        dispatch(getSigners((res)=>{
          let signer = _.find(res, x => getPropValue(x, "PROPERTY_277") === user.ID);
          if (signer) {
            _.forEach(deals, (deal)=>{
              let p = getPropValue(deal, "PROPERTY_290");
              if (p === signer.ID) {
                newDeals.push(deal);
              }
            })
          }
          callback(newDeals);
        }))
      } else {
        let signer = _.find(signers, x => getPropValue(x, "PROPERTY_277") === user.ID);
        if (signer) {
          _.forEach(deals, (deal)=>{
            let p = getPropValue(deal, "PROPERTY_290");
            if (p === signer.ID) {
              newDeals.push(deal);
            }
          })
        }
        callback(newDeals);
      }
    }

    /*let toolbarButtons;
    if (sb) {
      toolbarButtons = {new:true, process:true, archive:false, save:true, remove:true,close:true,send:true,settings:true};
    }*/
  
    const handlePageChange = (e, value)=>{
  
    }

    const handleEdit = (e)=>{
      let id = $(e.target).closest("td").attr("data-rec");
      navigate('new',  {state:{initDocId: id}});
    }

    const handleCheck = (e)=>{
      let id = $(e.target).closest("td").attr("data-rec");
      let checkId = $(e.target).closest("div").attr("data-check-id");
      navigate('check',  {state:{docId: id, checkId:checkId}});
    }
  
    const handleApproval = (e)=>{
      let id = $(e.target).closest("td").attr("data-rec");
      navigate('approval',  {state:{docId: id}});
    }

    const handleSign = (e)=>{
      let id = $(e.target).closest("td").attr("data-rec");
      navigate('sign',  {state:{docId: id}});
    }

    const handleRead = (e)=>{
      let id = $(e.target).closest("td").attr("data-rec");
      navigate('read',  {state:{docId: id}});
    }

    const handleAnswer = (e)=>{
      let id = $(e.target).closest("td").attr("data-rec");
      navigate('/answer',  {state:{docId: id}});
    }
  
    let user_actions = [];
    if (deals_in_process.length > 0) {
      if (sb) {
        _.forEach(deals_in_process, (d)=>{
          let status = getPropValue(d, "PROPERTY_314");
          let check = getPropValue(d, "PROPERTY_300");
          if (status && status === "На проверке") {
            let el = 
            <Tooltip key={d.ID} title="Проверить">
              <div data-check-id={check}>
                <IconButton  onClick={handleCheck}><AdjustIcon /></IconButton>
              </div>
            </Tooltip>
            addElementToActions(d.ID, el, "Проверить");
          } else {
            let el = 
            <Tooltip key={d.ID} title="Просмотр">
              <div>
                <IconButton  onClick={handleRead}><PreviewIcon /></IconButton>
              </div>
            </Tooltip>
            addElementToActions(d.ID, el, "Просмотр");
          }
        })
      } 
      if (isUserInitiator || isUserWorker) {
        _.forEach(deals_in_process, (d)=>{
          let status = getPropValue(d, "PROPERTY_314");
          let answerRequired = getPropValue(d, "PROPERTY_315");
          if (status && status === "Черновик") {
            let el =
            <Tooltip key={d.ID} title="Заполнить">
              <div>
                <IconButton  onClick={handleEdit}><EditIcon /></IconButton>
              </div>
            </Tooltip>
            addElementToActions(d.ID, el, "Заполнить");
          } else if (status && status === "На согласовании" && !answerRequired) {
            let el = 
            <Tooltip key={d.ID} title="Обзор">
              <div>
                <IconButton  onClick={handleAnswer}><AppRegistrationIcon /></IconButton>
              </div>
            </Tooltip>
            addElementToActions(d.ID, el, "Обзор");
          } else if (status && (status === "На согласовании" || status === "На проверке") && answerRequired) {
            let el =
            <Tooltip key={d.ID} title="Ответить">
              <div>
                <IconButton  onClick={handleAnswer}><QuickreplyIcon /></IconButton>
              </div>
            </Tooltip>
            addElementToActions(d.ID, el, "Ответить");
          } else if (status && status === "На подписании") {
            let el =
            <Tooltip key={d.ID} title="Подписать">
              <div>
                <IconButton  onClick={handleSign}><DrawIcon /></IconButton>
              </div>
            </Tooltip>
            addElementToActions(d.ID, el, "Ответить");
          } else {
            let el =
            <Tooltip key={d.ID} title="Просмотр">
              <div>
                <IconButton  onClick={handleRead}><PreviewIcon /></IconButton>
              </div>
            </Tooltip>
            addElementToActions(d.ID, el, "Просмотр");
          }
        })
      } 
      if (isUserSigner) {
        _.forEach(deals_in_process, (d)=>{
          let el =
          <Tooltip key={d.ID} title="Просмотр">
            <div>
              <IconButton  onClick={handleRead}><PreviewIcon /></IconButton>
            </div>
          </Tooltip>
          addElementToActions(d.ID, el, "Просмотр");
        })
      }
      if (isUserInLogs) {
        _.forEach(deals_in_process, (d)=>{
          let status = getPropValue(d, "PROPERTY_314");
          if (status === "На согласовании") {
            let found = false;
            _.forEach(d.LOGS_DATA, (logData)=>{
              let res = getPropValue(logData, "PROPERTY_307");
              if (res) {
                found = true;
                return false;
              }
            })
            if (found) {
              let el =
              <Tooltip key={d.ID} title="Отозвать">
                <div>
                  <IconButton onClick={handleApproval}><BlurOffIcon /></IconButton>
                </div>
              </Tooltip>
              addElementToActions(d.ID, el, "Отозвать");
            } else {
              let el =
              <Tooltip key={d.ID} title="Согласовать">
                <div>
                  <IconButton  onClick={handleApproval}><DeblurIcon /></IconButton>
                </div>
              </Tooltip>
              addElementToActions(d.ID, el, "Согласовать");
            }
          } else {
            let el =
            <Tooltip key={d.ID} title="Просмотр">
              <div>
                <IconButton  onClick={handleRead}><PreviewIcon /></IconButton>
              </div>
            </Tooltip>
            addElementToActions(d.ID, el, "Просмотр");
          }
        })
      }
    }

    //отчистка от лишних действий
    _.forEach(user_actions, (action)=>{
      let i = _.findIndex(action.elements, x => x.name === "Согласовать");
      let k = _.findIndex(action.elements, x => x.name === "Просмотр");
      if (i > -1 && k > -1) {
        action.elements = _.filter(action.elements, x => x.name !== "Просмотр");
      }

      i = _.findIndex(action.elements, x => x.name === "Заполнить");
      k = _.findIndex(action.elements, x => x.name === "Просмотр");
      if (i > -1 && k > -1) {
        action.elements = _.filter(action.elements, x => x.name !== "Просмотр");
      }

      i = _.findIndex(action.elements, x => x.name === "Проверить");
      k = _.findIndex(action.elements, x => x.name === "Просмотр");
      if (i > -1 && k > -1) {
        action.elements = _.filter(action.elements, x => x.name !== "Просмотр");
      }

      i = _.findIndex(action.elements, x => x.name === "Ответить");
      k = _.findIndex(action.elements, x => x.name === "Просмотр");
      if (i > -1 && k > -1) {
        action.elements = _.filter(action.elements, x => x.name !== "Просмотр");
      }

      i = _.findIndex(action.elements, x => x.name === "Обзор");
      k = _.findIndex(action.elements, x => x.name === "Просмотр");
      if (i > -1 && k > -1) {
        action.elements = _.filter(action.elements, x => x.name !== "Просмотр");
      }

      i = _.findIndex(action.elements, x => x.name === "Отозвать");
      k = _.findIndex(action.elements, x => x.name === "Просмотр");
      if (i > -1 && k > -1) {
        action.elements = _.filter(action.elements, x => x.name !== "Просмотр");
      }

      i = _.findIndex(action.elements, x => x.name === "Подписать");
      k = _.findIndex(action.elements, x => x.name === "Просмотр");
      if (i > -1 && k > -1) {
        action.elements = _.filter(action.elements, x => x.name !== "Просмотр");
      }

      //-------------
      
      i = _.findIndex(action.elements, x => x.name === "None");
      if (i > -1 && action.elements.length > 1) {
        action.elements = _.filter(action.elements, x => x.name !== "None");
      }
    })

    function addElementToActions(id, el, elName) {
      let ua = _.findIndex(user_actions, x => x.ID === id);
      if (ua === -1) {
        user_actions.push({ID:id, elements: [{element:el, name:elName}]});
      } else {
        let i = _.findIndex(user_actions[ua].elements, x => x.name === elName);
        if (i === -1) {
          user_actions[ua].elements.push({element:el, name:elName});
        }
      }
    }

    //для тестирования
    const handleLoginUser = (e)=>{
      dispatch(resetState({currentUser:null}));
      setUsetLogin(e.target.value);
    }

    const handleSearchToken = (e) => {
      setSearchToken(e.target.value);
    }

    const handleSearch = () => {
      temp_deals = [];
      setSearchText(searchToken);
    }
    
    return (
      <ThemeProvider theme={stateObj.theme}>
        <Column left={24} right={24} bottom={40}>
          <Row top={8}><ToolBar state="process"></ToolBar ></Row>
          { loading &&
          <LinearProgress />
          }
          {/* для тестирования */}
          { stateObj.isTest &&
          <Row top={16} width={300}>
            <FormControl fullWidth>
              <InputLabel id="select-users-label">Логин как пользователь (для тестов)</InputLabel>
              <Select
                labelId="select-users-label"
                id="select-users"
                value={loginUser}
                label="Логин как пользователь (для тестов)"
                onChange={handleLoginUser}
              >
                {stateObj.testUsers.map((item)=>{
                  return(<MenuItem key={item.ID} value={item.ID}>{item.LAST_NAME}</MenuItem>)
                })}
              </Select>
            </FormControl>
          </Row>
          }
          <Row top={32} bottom={16} cross={Axis.cross.center} main={Axis.main.between}>
            <Header1>Согласования в работе</Header1>
            <Row width={300}>
              <TextField
                fullWidth
                id="search-text"
                label="Текст"
                value={searchToken}
                onChange={handleSearchToken}
              />
              <Row left={8}><Button variant='contained' size='small' onClick={handleSearch} disabled={!loaded}>Поиск</Button></Row>
            </Row>
          </Row>
          <Row bottom={16} top={8}>{currentUser ? currentUser.LAST_NAME+" (ID: "+currentUser.ID+")": null }</Row>
          <Column>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{backgroundColor:"#d8e2ff"}}>
                    <TableCell align="left"><Header4>ID</Header4></TableCell>
                    <TableCell align="left"><Header4>Тип</Header4></TableCell>
                    <TableCell align="left"><Header4>Контрагент</Header4></TableCell>
                    <TableCell align="left"><Header4>Название</Header4></TableCell>
                    <TableCell align="left"><Header4>Дата</Header4></TableCell>
                    <TableCell align="left"><Header4>Номер</Header4></TableCell>
                    <TableCell align="left"><Header4>Статус</Header4></TableCell>
                    <TableCell align="center"><Header4>Ваши задания</Header4></TableCell>
                    { currentUser && currentUser.ID === "86" &&
                      <TableCell align="center"><Header4>Admin actions</Header4></TableCell>
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  { deals_in_process.map((rec, i)=>{
                    let docType = getMatches(rec.NAME, /[–-]\s*(.+)?[№#]/g, 1);
                    let name = getPropValue(rec, "PROPERTY_296");
                    let date = getPropValue(rec, "PROPERTY_291");
                    let num = getPropValue(rec, "PROPERTY_292");
                    let status = getPropValue(rec, "PROPERTY_314");
                    let partner = getMatches(rec.NAME, /^(.+)?\s[–-]/g, 1);
                    let check;
                    if (rec.PROPERTY_300) {
                      check = getPropValue(rec, "PROPERTY_300");
                    }
                    //console.log(user_actions);
                    let ua = _.find(user_actions, x => x.ID === rec.ID) ? _.find(user_actions, x => x.ID === rec.ID).elements : [];

                    return(
                      <TableRow key={rec.ID}>
                        <TableCell>{rec.ID}</TableCell >
                        <TableCell>{docType}</TableCell>  
                        <TableCell>{partner}</TableCell>  
                        <TableCell>{name}</TableCell>  
                        <TableCell>{date}</TableCell>  
                        <TableCell>{num}</TableCell>  
                        <TableCell>{status}</TableCell>  
                        <TableCell data-rec={rec.ID}><Row center>
                          { ua.map((a)=>{
                            return(
                              <Row key={uuid()}>{a.element}</Row>
                            )
                          })}
                        </Row></TableCell>
                        { currentUser && currentUser.ID === "86" &&
                          <TableCell data-rec={rec.ID}>
                          <Row center>
                            <Tooltip title="Заполнить">
                              <div>
                                <IconButton  onClick={handleEdit}><EditIcon></EditIcon></IconButton>
                              </div>
                            </Tooltip>
                            <Row left={3} right={3} width={1} height={30} color="gray"></Row>
                            { check &&
                            <Tooltip title="Проверить контрагента">
                              <div data-check-id={check}>
                                <IconButton  onClick={handleCheck}><AdjustIcon /></IconButton>
                              </div>
                            </Tooltip>
                            }
                            <Row left={3} right={3} width={1} height={30} color="gray"></Row>
                            <Tooltip title="Согласовать">
                              <div>
                                <IconButton  onClick={handleApproval}><DeblurIcon /></IconButton>
                              </div>
                            </Tooltip>
                            <Tooltip title="Отозвать согласование">
                              <div>
                                <IconButton onClick={handleApproval}><BlurOffIcon /></IconButton>
                              </div>
                            </Tooltip>
                            <Row left={3} right={3} width={1} height={30} color="gray"></Row>
                            <Tooltip title="Подписать">
                              <div>
                                <IconButton  onClick={handleSign}><DrawIcon /></IconButton>
                              </div>
                            </Tooltip>
                            <Row left={3} right={3} width={1} height={30} color="gray"></Row>
                            <Tooltip title="Ответить (требуется ответ)">
                              <div>
                                <IconButton  onClick={handleAnswer}><QuickreplyIcon /></IconButton>
                              </div>
                            </Tooltip>
                            <Tooltip title="Обзор (ответ не требуется)">
                              <div>
                                <IconButton  onClick={handleAnswer}><AppRegistrationIcon /></IconButton>
                              </div>
                            </Tooltip>
                            <Row left={3} right={3} width={1} height={30} color="gray"></Row>
                            <Tooltip title="Просмотр (только чтение)">
                              <div>
                                <IconButton  onClick={handleRead}><PreviewIcon /></IconButton>
                              </div>
                            </Tooltip>
                          </Row>
                        </TableCell>}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {/*<Row top={24} bottom={0} center>
              <Pagination count={10} page={1} onChange={handlePageChange} />
                </Row>*/}
          </Column>
        </Column>
      </ThemeProvider>
    );
  }
  
export default InProcessPage;
  



