import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Column, Stack, StackItem, Axis, ContentContainer, Dbg, Debug } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, Caption, Overline, ProductName, Price, ErrorMessage, Title } from './Typography';
import _ from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getPropValue } from './helpers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import { 
    getDeal,
    resetState,
    updateCalculatedLayout,
    getDataForDeal
} from "./redux/actions";

function DealInfoBlock(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const $ = window.$;
  const [partnerValue, setPartnerValue] = React.useState('');
  const [docType, setDocType] = React.useState('');
  const [docTypeName, setDocTypeName] = React.useState('');
  const [nameDocValue, setNameDoc] = React.useState('');
  const [parentDocValue, setParentDoc] = React.useState('');
  const [amountDoc, setAmountDoc] = React.useState('');
  const [crcValue, setCrcValue] = React.useState('');
  //const [crcName, setCrcName] = React.useState('');
  const [signerValue, setSignerValue] = React.useState('');
  const [signerName, setSignerName] = React.useState('');
  //const [docDate, setDocDate] = React.useState('');
  const [whoLoad, setWhoLoad] = React.useState('');
  const [signingTypeValue, setSigningTypeValue] = React.useState('');
  const [dateValue, setDate] = React.useState(null);
  const [numDoc, setNumDoc] = React.useState('');
  const [initiatorValue, setInitiator] = React.useState('');
  const [workerValue, setWorker] = React.useState('');
  const [urlPartner, setUrlPartner] = useState("#");
  const [crc, setCrc] = useState(null);

  useEffect(()=>{
    dispatch(resetState({dealBlockInfoData:{}}));
    dispatch(getDataForDeal(props.docId, (results)=>{
      //console.log(results);
      let dealBlockInfoData = {};

      let doc_types = _.find(results, (x)=>{return x.hasOwnProperty("doc_types")}).doc_types;
      let signers = _.find(results, (x)=>{return x.hasOwnProperty("signers")}).signers;
      let crc = _.find(results, (x)=>{return x.hasOwnProperty("crc")}).crc;
      setCrc(crc);
      //console.log(crc);
      //dispatch(resetState({crc:crc}));
      let deal = _.find(results, (x)=>{return x.hasOwnProperty("deal")}) ? _.find(results, (x)=>{return x.hasOwnProperty("deal")}).deal : null;
      let partner = _.find(results, (x)=>{return x.hasOwnProperty("partner")}) ? _.find(results, (x)=>{return x.hasOwnProperty("partner")}).partner : null;
      let signer = _.find(results, (x)=>{return x.hasOwnProperty("signer")}) ? _.find(results, (x)=>{return x.hasOwnProperty("signer")}).signer : null;
      let worker = _.find(results, (x)=>{return x.hasOwnProperty("worker")}) ? _.find(results, (x)=>{return x.hasOwnProperty("worker")}).worker : null;
      let initiator = _.find(results, (x)=>{return x.hasOwnProperty("initiator")}) ? _.find(results, (x)=>{return x.hasOwnProperty("initiator")}).initiator : null;
      let parent_doc = _.find(results, (x)=>{return x.hasOwnProperty("parent_doc")}) ? _.find(results, (x)=>{return x.hasOwnProperty("parent_doc")}).parent_doc : null;
      
      //console.log(deal);
      setPartnerValue(partner);
      setDocType(getPropValue(deal, "PROPERTY_286"));
      let dt = getPropValue(deal, "PROPERTY_286");
      setDocTypeName(_.find(doc_types.result, x => x.ID === dt).NAME);
      setNameDoc(getPropValue(deal, "PROPERTY_296"));
      setParentDoc(parent_doc);
      let amountDoc = getPropValue(deal, "PROPERTY_293");
      setAmountDoc(amountDoc);
      dealBlockInfoData = {...dealBlockInfoData, amountDoc};
      //dispatch(resetState({dealBlockInfoData:{...stateObj.dealBlockInfoData, amountDoc}}));
      setSignerValue(getPropValue(deal, "PROPERTY_290"));
      //console.log(signers);
      try {
        let sn = _.find(signers, x => x.ID === getPropValue(deal, "PROPERTY_290")).NAME;
        setSignerName(sn);
      } catch(e) {}
      setWhoLoad(getPropValue(deal, "PROPERTY_287"));
      let _dt = getPropValue(deal, "PROPERTY_291");
      if (_dt) {
        //setDocDate(_dt);
        let parts = _dt.split(".");
        let dateValue = dayjs(new Date(parts[2], parts[1]-1, parts[0]));
        dealBlockInfoData = {...dealBlockInfoData, dateValue};
        //dispatch(resetState({dealBlockInfoData:{...stateObj.dealBlockInfoData, dateValue}}));
        setDate(dateValue);
      }
      setSigningTypeValue(getPropValue(deal, "PROPERTY_289"));
      let numDoc = getPropValue(deal, "PROPERTY_292");
      setNumDoc(numDoc);
      dealBlockInfoData = {...dealBlockInfoData, numDoc};
      //dispatch(resetState({dealBlockInfoData:{...stateObj.dealBlockInfoData, numDoc}}));
      let crcValue = getPropValue(deal, "PROPERTY_294") ? getPropValue(deal, "PROPERTY_294") : "";
      setCrcValue(crcValue);
      dealBlockInfoData = {...dealBlockInfoData, crcValue};
      //dispatch(resetState({dealBlockInfoData:{...stateObj.dealBlockInfoData, crcValue}}));    
      /*let cc = _.find(crc, x => x.ID === getPropValue(deal, "PROPERTY_294")).NAME;
      setCrcName(cc);*/
      setInitiator(initiator);
      setWorker(worker);
      setUrlPartner(`https://mirastroyservice.ru/crm/company/details/${partner.ID}/`);
      dispatch(resetState({dealBlockInfoData:dealBlockInfoData}));
    }))
  },[]);

  const handleAmountDoc = (e) => {
    dispatch(resetState({dealBlockInfoData:{...stateObj.dealBlockInfoData, amountDoc:e.target.value}}));
    setAmountDoc(e.target.value);
  }

  const handleCrc = (event) => {
    dispatch(resetState({dealBlockInfoData:{...stateObj.dealBlockInfoData, crcValue:event.target.value}}));
    setCrcValue(event.target.value);
  }

  const handleChangeDate = (newValue) => {
    let d = dayjs(new Date(newValue.$d));
    dispatch(resetState({dealBlockInfoData:{...stateObj.dealBlockInfoData, dateValue:d}}));
    setDate(d);
    //console.log(d);
  }

  const handleNumDoc = (e) => {
    dispatch(resetState({dealBlockInfoData:{...stateObj.dealBlockInfoData, numDoc:e.target.value}}));
    setNumDoc(e.target.value);
  }

  //console.log(dateValue);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
      <Column>
        <TableContainer>
          <Table sx={{ minWidth: props.minWidth }} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell><Subtitle1>Контрагент в CRM:</Subtitle1></TableCell >
                <TableCell><a href={urlPartner} target="_blank">{partnerValue ? partnerValue.TITLE : ""}</a></TableCell >
              </TableRow>
              <TableRow>
                <TableCell><Subtitle1>Тип документа:</Subtitle1></TableCell >
                <TableCell>{docTypeName ? docTypeName : ""}</TableCell >
              </TableRow>
              <TableRow>
                <TableCell><Subtitle1>Название:</Subtitle1></TableCell >
                <TableCell>{nameDocValue ? nameDocValue : ""}</TableCell >
              </TableRow>
              { parentDocValue &&
              <TableRow>
                <TableCell><Subtitle1>Родительский документ:</Subtitle1></TableCell >
                <TableCell>{parentDocValue ? parentDocValue.NAME : ""}</TableCell >
              </TableRow>
              }
              { props.editable === true ?
              <TableRow>
                <TableCell><Subtitle1>Сумма документа:</Subtitle1></TableCell >
                <TableCell>
                  <TextField
                    id="amount-doc"
                    type='number'
                    value={amountDoc}
                    onChange={handleAmountDoc}
                  />
                </TableCell >
              </TableRow>
              :
              <TableRow>
                <TableCell><Subtitle1>Сумма документа:</Subtitle1></TableCell >
                <TableCell>{amountDoc ? amountDoc : ""}</TableCell>
              </TableRow>
              }
              { props.editable === true ?
              <TableRow>
                <TableCell><Subtitle1>Валюта:</Subtitle1></TableCell >
                <TableCell>
                  <Row width={100}>
                    <FormControl fullWidth>
                      <Select
                        labelId="select-crc-label"
                        id="select-crc"
                        value={crcValue}
                        onChange={handleCrc}
                      >
                        {crc && crc.map((item)=>{
                          return(<MenuItem key={item.ID} value={item.ID}>{item.NAME}</MenuItem>)
                        })}
                      </Select>
                    </FormControl>
                  </Row>
                </TableCell >
              </TableRow>
              :
              <TableRow>
                <TableCell><Subtitle1>Валюта:</Subtitle1></TableCell >
                <TableCell>{_.find(crc, x => x.ID === crcValue) ? _.find(crc, x => x.ID === crcValue).NAME : ""}</TableCell >
              </TableRow>
              }
              <TableRow>
                <TableCell><Subtitle1>Подписант:</Subtitle1></TableCell >
                <TableCell>{signerName ? signerName : ""}</TableCell >
              </TableRow>
              <TableRow>
                <TableCell><Subtitle1>Кто отгружает:</Subtitle1></TableCell >
                <TableCell>{whoLoad ? whoLoad : ""}</TableCell >
              </TableRow>
              <TableRow>
                <TableCell><Subtitle1>Подписание:</Subtitle1></TableCell >
                <TableCell>{signingTypeValue ? signingTypeValue : ""}</TableCell >
              </TableRow>
              {props.editable === true ?
              <TableRow>
                <TableCell><Subtitle1>Дата документа:</Subtitle1></TableCell >
                <TableCell>
                  <Row width={150}>
                    <FormControl fullWidth>
                      <DatePicker value={dateValue} onChange={handleChangeDate}></DatePicker>
                    </FormControl>
                  </Row>
                </TableCell >
              </TableRow>
              :
              <TableRow>
                <TableCell><Subtitle1>Дата документа:</Subtitle1></TableCell >
                <TableCell>{dateValue ? `${dateValue.$D}.${dateValue.$M+1}.${dateValue.$y}`: ""}</TableCell >
              </TableRow>
              }
              {props.editable === true ?
              <TableRow>
                <TableCell><Subtitle1>Номер документа:</Subtitle1></TableCell >
                <TableCell>
                  <Row width={180}>
                    <TextField
                      id="num-doc"
                      value={numDoc}
                      onChange={handleNumDoc}
                    />
                  </Row>
                </TableCell >
              </TableRow>
              :
              <TableRow>
                <TableCell><Subtitle1>Номер документа:</Subtitle1></TableCell >
                <TableCell>{numDoc ? numDoc : ""}</TableCell >
              </TableRow>
              }
              <TableRow>
                <TableCell><Subtitle1>Инициатор:</Subtitle1></TableCell >
                <TableCell>{initiatorValue ? `${initiatorValue.NAME} ${initiatorValue.LAST_NAME} - ${initiatorValue.WORK_POSITION}` : ""}</TableCell >
              </TableRow>
              <TableRow>
                <TableCell><Subtitle1>Исполнитель:</Subtitle1></TableCell >
                <TableCell>{workerValue ? `${workerValue.NAME} ${workerValue.LAST_NAME} - ${workerValue.WORK_POSITION}` : ""}</TableCell >
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Column>
    </LocalizationProvider>
  );
}
  
export default DealInfoBlock;
  



